import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import NavigationButtons from "../components/NavigationButtons"
import useEnglish from "../library/useEnglish"
import TextPage from "../pagesLayouts/TextPage"
import { WordpressPageQuery } from "../types/schema"

const navigation = {
  left: {
    href: "/classes",
    text: "CLASSES",
  },
  right: {
    href: "/timetable",
    text: "BUY",
  },
}

export default function TeamEn() {
  useEnglish()

  const query = graphql`
    query {
      wpPage(language: { slug: { eq: "en" } }, slug: { eq: "team-en" }) {
        ...WordpressPageFragment
      }
    }
  `
  const data = useStaticQuery<WordpressPageQuery>(query)

  return (
    <TextPage data={data} language="en">
      <NavigationButtons left={navigation.left} right={navigation.right} />
    </TextPage>
  )
}
